<template>
	<section class="play">
		<div class="paly-main" v-if="!is_show">
			<div class="bg">
			</div>
		</div>

		<!--打开游戏-->
		<div class="game-main">
			<iframe v-if="is_show" v-show="iframeShow" id="gameIframe" :src="iframeUrl" frameborder="0" scrolling="auto"
				width="100%" height="100%" ref="h5CourseWare">
			</iframe>
		</div>

		<!-- 登录弹框 -->
		<LoginDialog :centerDialogVisible="DialogVisible" :gameId="game_id" @childFun="childFun"></LoginDialog>

		<!--调起支付-->
		<div class="game-pay">
			<el-dialog title="" :visible.sync="pcDialogVisible" :modal-append-to-body="false" @close="closePay"
				@open="payAction(payActive)" :close-on-click-modal="false" :show-close="false" center>
				<div class="pay-box">
					<h1>支付中心</h1>
					<div class="pay-main">
						<el-avatar :size="160" src="https://empty" shape="square" v-loading="payLoading">
							<img :src="payImg" />
						</el-avatar>
						<div class="pay-info">
							<h2>游戏充值</h2>
							<div class="pay-money">
								充值 <span>{{payMenuy}}</span> 元
							</div>
							<div class="pay-btn">
								<div :class="{'pay-active': payActive == 'wechatpay'?true: false}"
									@click="payAction('wechatpay')">
									<el-avatar :size="30" shape="square">
										<img src="../assets/images/icon_weixinzhifu.png" />
									</el-avatar>
									<span class="pay-font">微信支付</span>
								</div>
								<div :class="{'pay-active': payActive == 'alipay'?true: false}"
									@click="payAction('alipay')">
									<el-avatar :size="30" shape="square">
										<img src="../assets/images/icon_zhifubaozhufu.png" />
									</el-avatar>
									<span class="pay-font">支付宝支付</span>
								</div>
							</div>
						</div>
					</div>
					<div class="closeBtn">
						<img src="../assets/images/btn_icon_guangbi_default.png" alt="" @click="closePay">
					</div>
				</div>
			</el-dialog>

			<el-dialog title="" :visible.sync="phoneDialogVisible" :modal-append-to-body="false" @close="closePay"
				:close-on-click-modal="false" :show-close="false" center>
				<div class="pay-box-phone">
					<h1>支付中心</h1>
					<div class="pay-info">
						<div class="pay-money">
							充值金额 <span>{{payMenuy}}</span> 元
						</div>
						<div class="pay-btn">
							<div @click="payAction('wechatpay')">
								<el-avatar :size="30" shape="square">
									<img src="../assets/images/icon_weixinzhifu.png" />
								</el-avatar>
								<span class="pay-font">微信支付</span>
							</div>
							<div @click="payAction('alipay')">
								<el-avatar :size="30" shape="square">
									<img src="../assets/images/icon_zhifubaozhufu.png" />
								</el-avatar>
								<span class="pay-font">支付宝支付</span>
							</div>
						</div>
						<div class="closeBtn">
							<img src="../assets/images/btn_icon_guangbi_default.png" alt="" @click="closePay">
						</div>
					</div>
				</div>
			</el-dialog>
		</div>

		<!-- 实名弹窗 -->
		<el-dialog title="实名认证" width="80%" :visible.sync="dialogVisibleSm" :modal-append-to-body="false"
			@close="closePay" :close-on-click-modal="false" :show-close="false" center>
			<div style="color: red;">根据政府相关规定，您需要完成实名认证后方可继续游戏。</div>
			<el-form :model="sm">
				<el-form-item label="姓名">
					<el-input placeholder="你在此输入您的真实姓名" v-model="sm.true_name"></el-input>
				</el-form-item>
				<el-form-item label="身份证号码">
					<el-input placeholder="请输入身份证号码" v-model="sm.card"></el-input>
				</el-form-item>
				<el-form-item label="">
					<el-button type="primary" @click="subSm">提交</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>

		<!-- 微信支付强制跳转url -->
		<a id="href_true" style="display: none;"></a>
	</section>
</template>

<script>
	import LoginDialog from "@/components/LoginDialog/LoginDialog";
	export default {
		name: "Play",
		data() {
			return {
				is_show: false,
				iframeShow: false,
				iframeUrl: null,
				DialogVisible: false,
				game_id: null,
				pcDialogVisible: false,
				phoneDialogVisible: false,
				payActive: 'wechatpay',
				timer: null,
				payImg: null,
				payMenuy: null,
				loading: null,
				payLoading: true,
				// 实名弹窗
				dialogVisibleSm: false,
				sm: {
					true_name: null,
					card: null
				},
			}
		},
		watch: {
			'$store.state.iframeIsShow': function(item, value) {
				if (this.isPc()) {
					this.pcDialogVisible = true
				} else {
					this.phoneDialogVisible = true
				}
			},
			'$store.state.topUpInfo': function(item, value) {
				this.payMenuy = this.$store.state.topUpInfo.data.total_fee
			}
		},
		created() {
			this.loading = this.$loading({
				lock: true,
				text: '游戏正在加载中...',
				spinner: 'el-icon-loading',
				background: 'rgba(0, 0, 0, 0.7)'
			});
			this.game_id = this.$route.query.gameId
			let token = this.$route.query.token
			if (token) {
				this.Public.setCookie('token', token)
			}
			// 获取游戏地址
			this.palyGame()
		},
		methods: {
			// 页面开始加载游戏
			palyGame() {
				let userId = localStorage.getItem('userId')
				userId = userId + "-h5Game"
				// userId = "5-yunjiwan"
				this.sm.user_id = userId
				this.getSmrz(userId)
			},

			// 查询实名信息
			getSmrz(userId) {
				this.box2Request({
					url: "/yunjiwan/Smrz/getSmrz?user_id=" + userId
				}).then(res => {
					if (res.data.data) {
						let card = res.data.data.card
						if (this.Public.testIdcard(card)) {
							this.startGame()
						} else {
							this.$alert('根据政府相关规定， 未成年玩家只能在规定时间内游戏。', '提示', {
								confirmButtonText: '确定',
								callback: action => {
									this.loading.close()
									this.$router.push("/")
								}
							});
						}
					} else {
						console.log("未实名")
						this.dialogVisibleSm = true
					}
				})
			},

			// 提交实名
			subSm() {
				// 验证姓名是否合法
				if (this.sm.true_name && this.sm.card) {
					if (this.sm.true_name.length >= 2 && this.sm.true_name.length <= 5) {
						this.saveSm()
					} else {
						this.$message.error("请输入合法的身份证姓名")
					}
				} else {
					this.$message.error("姓名或身份证号不能为空")
				}
				// 验证身份证是否合法
			},

			// 保存实名信息
			saveSm() {
				this.box2Request({
					url: "/yunjiwan/Smrz/setSmrz",
					method: "POST",
					data: this.sm
				}).then(res => {
					if (res.data.code == "0") {
						this.$router.go(0)
					} else {
						this.$message.error(res.data.msg)
					}
				})
			},

			// 开始游戏
			startGame() {
				var token = this.Public.getCookie('token')
				if (token != 'null' && token) {
					// 获取iframe地址
					this.h5Request({
						url: '/api/h5/game/url/?u_token=' + token + '&game_id=' + this.game_id
					}).then(result => {
						if (result.data.code == 200) {
							if (this.Public.isHttps()) {
								// 判断当前连接是否是https
								if (result.data.data.indexOf("https") != -1) {
									this.iframeUrl = result.data.data;
								} else {
									this.iframeUrl = result.data.data.replace(/http/, 'https')
								}
							} else {
								this.iframeUrl = result.data.data;
							}
							this.is_show = true
							this.iframeShow = true
							this.loading.close()
						} else {
							this.loading.close()
							this.$message.error('获取游戏信息失败，无法正常进行游戏，请联系管理员！');
						}
					})
				} else {
					this.loading.close()
					// 让用户进行登录
					this.DialogVisible = true
				}
			},

			childFun(value) {
				this.DialogVisible = value
			},
			closePay() {
				this.payImg = null
				this.payMenuy = null
				this.payActive = 'wechatpay'
				this.iframeShow = true
				this.pcDialogVisible = false
				this.phoneDialogVisible = false
				this.payLoading = true
			},
			payAction(value) {
				clearInterval(this.timer)
				this.payActive = value;
				this.payLoading = true
				var payType = '';
				// 获取支付信息
				var param = this.$store.state.topUpInfo
				if (value == 'alipay') {
					param.pay_mold = this.isPc() ? 'ALPAGE' : 'ALWAP'
				} else {
					param.pay_mold = this.isPc() ? 'WXPAGE' : 'WXWAP'
				}

				this.h5Request({
					url: '/api/pay/create/',
					method: 'post',
					data: param
				}).then(result => {
					if (result.data.code == 200) {
						this.payLoading = false
						switch (result.data.data.pay_mold) {
							case "WXPAGE":
								this.payImg = result.data.data.code_url
								this.$message('请使用微信，扫描左边二维码进行支付！');
								break;
							case "WXWAP":
								document.getElementById("href_true").href = result.data.data.code_url
								document.getElementById("href_true").click(); //模拟点击
								// window.location.href = result.data.data.code_url
								break;
							case "ALPAGE":
								this.$message('请使用支付宝，扫描左边二维码进行支付！');
								this.payImg = result.data.data.code_url
								break;
							case "ALWAP":
								window.location.href = result.data.data.code_url
								break;
						}
						var that = this;
						// 当前订单编号
						var orderId = result.data.data.trade_id;
						// 轮训查询订单状态
						this.timer = setInterval(() => {
							that.queryOrderStatus(orderId).then(function(res) {
								if (res) {
									that.$message({
										message: '充值成功！',
										type: 'success'
									});
									that.closePay()
									clearInterval(that.timer)
								}
							})
						}, 3000)
					} else {
						this.$message({
							message: '支付失败！请求参数异常，请联系管理员！',
							type: 'error'
						});
						that.closePay()
						clearInterval(that.timer)
					}
				})
			},
			// 查询订单状态
			async queryOrderStatus(orderId) {
				return await this.h5Request({
					url: "/api/pay/check/" + orderId + "/"
				}).then(result => {
					return result.data.code == 200 && result.data.data == 'SUCCESS' ? true : false;
				})
			},
			isPc() {
				if ((navigator.userAgent.match(
						/(iPhone|iPod|Android|ios|iOS|iPad|Backerry|WebOS|Symbian|Windows Phone|Phone)/i))) {
					return false;
				} else {
					return true;
				}
			}
		},
		components: {
			LoginDialog
		}
	}
</script>

<style>
	.game-pay .el-dialog {
		width: 3.125rem !important;
		height: 1.5625rem;
		border-radius: 16px;
	}

	@media (max-width: 768px) {
		.game-pay .el-dialog {
			width: 80% !important;
			height: auto;
			border-radius: 16px;
		}
	}

	.game-pay .el-dialog--center .el-dialog__body {
		padding: 0 !important;
	}

	.game-pay .el-dialog__header {
		padding: 0 !important;
	}
</style>

<style scoped lang="less">
	.play {
		width: 100%;
		height: 100%;
	}

	.paly-main {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: #000000;

		.bg {
			width: 500px;
			height: 100%;
			// background: url("../assets/images/bg1.jpg") no-repeat;
			background-size: cover;
			background-position: center;
			margin: 0 auto;
		}
	}

	.game-main {
		position: absolute;
		width: 100%;
		height: 100%;
	}

	.pay-box {
		position: relative;
		width: 100%;
		box-sizing: border-box;
		padding: 0.104rem 0.15625rem;

		h1 {
			font-size: 0.125rem;
			font-family: Microsoft YaHei;
			color: #383838;
		}

		.pay-main {
			display: flex;
			width: 100%;
			margin-top: 0.13rem;

			.el-avatar {
				width: 0.833rem !important;
				height: 0.833rem !important;
				line-height: 0.833rem !important;
			}

			.pay-info {
				width: calc(100% - 0.833rem);
				box-sizing: border-box;
				padding-left: .1rem;

				h2 {
					font-size: .1rem;
					font-family: Microsoft YaHei;
					font-weight: 400;
					color: #383838;
				}

				.pay-money {
					margin-top: .12rem;
					font-size: .1rem;
					font-family: Microsoft YaHei;
					font-weight: 400;
					color: #383838;

					span {
						font-size: .167rem;
						font-family: Microsoft YaHei;
						font-weight: bold;
						color: #FF7431;
						margin: 0 0.0521rem;
					}
				}

				.pay-btn {
					display: flex;
					margin-top: .1rem;

					.el-avatar {
						background: none;
					}

					div {
						display: flex;
						align-items: center;
						width: 0.78125rem;
						background: #FFFFFF;
						border: 1px solid #A1A1A1;
						border-radius: 4px;
						padding: 0.0521rem;
						cursor: pointer;

						.el-avatar {
							width: 0.15625rem !important;
							height: 0.15625rem !important;
							line-height: 0.15625rem !important;
						}

						.pay-font {
							margin-left: 0.0521rem;
							font-size: 0.09375rem;
							font-family: Microsoft YaHei;
							color: #383838;
						}
					}

					div:nth-of-type(1) {
						margin-right: 0.182rem;
					}

					.pay-active {
						border: 1px solid #FF7431;
					}
				}
			}
		}

		.closeBtn {
			display: inline-block;
			position: absolute;
			top: 5px;
			right: -0.42rem;
			width: 0.266rem;
			height: 0.266rem;

			img {
				width: 100%;
				height: 100%;
			}
		}
	}

	.pay-box-phone {
		position: relative;
		width: 100%;
		box-sizing: border-box;
		padding: 0.304rem 0.35625rem;

		h1 {
			font-size: 0.425rem;
			font-weight: bold;
			font-family: Microsoft YaHei;
			color: #383838;
		}

		.pay-info {
			margin-top: 10px;

			.pay-money {
				margin-top: .12rem;
				font-size: .5rem;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #383838;

				span {
					font-size: .77rem;
					font-family: Microsoft YaHei;
					font-weight: bold;
					color: #FF7431;
					margin: 0 0.321rem;
				}
			}

			.pay-btn {
				display: flex;
				margin-top: .4rem;

				.el-avatar {
					background: none;
				}

				div {
					display: flex;
					align-items: center;
					width: 50%;
					background: #FFFFFF;
					border: 1px solid #A1A1A1;
					border-radius: 4px;
					padding: 0.0521rem;
					cursor: pointer;

					.el-avatar {
						width: 0.8rem !important;
						height: 0.8rem !important;
						line-height: 0.8rem !important;
					}

					.pay-font {
						margin-left: 0.0521rem;
						font-size: 0.4rem;
						font-family: Microsoft YaHei;
						color: #383838;
					}
				}

				div:nth-of-type(1) {
					margin-right: 0.182rem;
				}

				.pay-active {
					border: 1px solid #FF7431;
				}
			}

			.closeBtn {
				display: inline-block;
				position: absolute;
				top: 5px;
				right: -0.72rem;
				width: 0.566rem;
				height: 0.566rem;

				img {
					width: 100%;
					height: 100%;
				}
			}
		}
	}

	@media (max-width: 768px) {
		.paly-main {
			.bg {
				width: 100%;
			}
		}

		.pay-box {
			display: none;
		}
	}
</style>
